import { FC } from 'react';
import { useFormikContext } from 'formik';
import { TopButton } from './top-button';
import { TopMobileMenu } from './top-menu';
import { SelectFrontEdge } from '../controls/select-front-edge';
import wallLedgeImg from '../../../assets/ledges/wallLedge.png';
import { ICountertopEdges, TMain } from '../../../types';
import s from './style.module.css';

export const ProductOptionsMenu: FC<{
  product: 'countertop' | 'bar' | 'island';
}> = ({ product }) => {
  const { values } = useFormikContext<TMain>();
  const { panels } = values.calculator.countertop;

  const isSide =
    values.calculator.island?.edges.edge1 === 'side' ||
    values.calculator.island?.edges.edge2 === 'side' ||
    values.calculator.island?.edges.edge3 === 'side' ||
    values.calculator.island?.edges.edge4 === 'side';

  const isPlinth = (): boolean => {
    const edges = values.calculator.countertop.edges;
    const results = Object.keys(edges).map((el) => {
      if (edges[el as keyof ICountertopEdges] === 'plinth') {
        return true;
      }
      return false;
    });

    return !!results.find((el) => el);
  };

  if (values.settings.isReadOnly) return null;

  return (
    <div className={s.countertopOtionsMenu}>
      {product !== 'bar' && <TopMobileMenu product={product} />}
      <SelectFrontEdge product={product} />

      {product === 'countertop' && (
        <>
          <TopButton
            label="Стеновая панель"
            active={panels.length > 0}
            type="panels"
          />

          <TopButton
            label="Плинтус"
            active={isPlinth()}
            type="wallLedge"
            img={wallLedgeImg}
          />
        </>
      )}

      {product === 'island' && (
        <TopButton
          label="Опора"
          active={isSide}
          type="lowering"
          img={wallLedgeImg}
        />
      )}
    </div>
  );
};
