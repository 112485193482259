import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { TMain } from '../../../../types';
import devider from '../../../../assets/common/devider.svg';
import {
  countertopL,
  countertopQ,
  countertopU,
} from '../../../app/defaultValues';
import s from './style.module.css';

type TActiveTab = 'Q' | 'L' | 'U';

export const TopSelector: FC = () => {
  const { setFieldValue } = useFormikContext<TMain>();

  const [activeTab, setActiveTab] = useState<TActiveTab>('Q');

  useEffect(() => {
    if (activeTab === 'Q') {
      setFieldValue('calculator.countertop', countertopQ, true);
    }

    if (activeTab === 'L') {
      setFieldValue('calculator.countertop', countertopL, true);
    }

    if (activeTab === 'U') {
      setFieldValue('calculator.countertop', countertopU, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div className={s.typeSelector}>
      <button
        onClick={() => {
          setActiveTab('Q');
        }}
        className={`pm14 ${s.button} ${activeTab === 'Q' ? s.active : ''}`}
      >
        Прямая
        <img className={s.leftDevider} src={devider} alt="" />
      </button>
      <button
        onClick={() => {
          setActiveTab('L');
        }}
        className={`${s.button} ${activeTab === 'L' ? s.active : ''}`}
      >
        Г–образная
      </button>
      <button
        onClick={() => setActiveTab('U')}
        className={`${s.button} ${activeTab === 'U' ? s.active : ''}`}
      >
        <img className={s.rightDevider} src={devider} alt="" />
        П–образная
      </button>
    </div>
  );
};
