import { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import { TEdgeName, TMain } from '../../../../types';
import { Icon } from '../../../common/icon';
import { Close } from '../close';
import s from './style.module.css';
import { MobileSwitch } from './mobile-switch';

interface IButtonSwitch {
  position: TEdgeName;
}

export const ButtonSwitch: FC<IButtonSwitch> = ({ position }) => {
  const [visible, setVisible] = useState(false);
  const { values, setFieldValue } = useFormikContext<TMain>();
  const [visibleMobile, setVisibleMobile] = useState(false);
  const { isMobile, isReadOnly } = values.settings;
  const { type, edges } = values.calculator.countertop;
  const name = `calculator.countertop.edges.${position}`;

  const edgeType: 'edge' | 'plinth' = edges[position] as 'edge' | 'plinth';

  const styleName = s[`${edgeType}Switch`];
  const styleNameMobile = s[`${edgeType}SwitchMobile`];

  const handleChange = (val?: null | 'plinth' | 'edge') => {
    if (val === null) {
      setFieldValue(name, null);
      return;
    }
    if (edgeType === 'edge') setFieldValue(name, 'plinth');
    if (edgeType === 'plinth') setFieldValue(name, 'edge');
  };
  const handleOver = () => setVisible(true);
  const handleLeave = () => setTimeout(() => setVisible(false), 1000);
  const handleMobile = () => {
    setVisibleMobile(!visibleMobile);
  };

  if (!isMobile && edges[position]) {
    return (
      <div
        onMouseOver={handleOver}
        onMouseLeave={handleLeave}
        className={`${styleName} ${s[type]} ${s[position]}`}
      >
        <button onClick={() => handleChange()} className={s.tooltip}>
          <span className={s.tooltiptext}>Кромка</span>К
        </button>

        <button onClick={() => handleChange()} className={s.tooltip}>
          <span className={s.tooltiptext}>Плинтус</span>П
        </button>

        {visible && <Close onClick={() => handleChange(null)} />}
      </div>
    );
  }

  if (isMobile && edges[position]) {
    return (
      <div
        onClick={handleMobile}
        className={`${styleNameMobile} ${s[type]} ${s[position]}`}
        style={visibleMobile ? { zIndex: '2' } : undefined}
      >
        {edgeType === 'edge' ? 'К' : 'П'}
        {visibleMobile && (
          <MobileSwitch
            fieldName={name}
            onOutside={setVisibleMobile}
            edgeType={edgeType}
          />
        )}
      </div>
    );
  }

  return (
    <>
      {!isReadOnly && (
        <button
          className={`${s.addButton} ${s[type]} ${s[position]}`}
          onClick={() => setFieldValue(name, 'edge')}
        >
          <Icon img="plus-blue" width={30} />
        </button>
      )}
    </>
  );
};
