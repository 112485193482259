import { TMain } from '../../types';

export const initialValues: TMain = {
  calculator: {
    countertop: {
      isActive: true,
      type: 'Q',
      sizes: {
        length1: 300, // 60...1000
        width1: 60, // 10...300
        length2: 60, // 10...300
        width2: 150, // 70...1000
        length3: 60, // 10...300
        width3: 150, // 70...1000
        thickness: 2, // 2,4
      },
      edges: {
        edge1: 'edge',
        edge2: 'edge',
        edge3: 'edge',
        edge4: 'edge',
        edge5: 'edge',
        edge6: 'edge',
        edge7: 'plinth',
        edge8: 'edge',
      },
      edgeType: null,
      wallLedge: true,
      sink: null,
      sinkQuantity: 0,
      hob: null,
      panels: [{ name: 'front', width: '60', height: '60', isActive: true }],
    },
    island: null,
    bar: null,
    material: {
      type: 'acryl',
      color: null,
      brand: null,
      uid: null,
    },
    options: {
      outlets: null,
      ventHoles: false,
      drainGroves: false,
      hotRods: false,
    },
    comments: [],
    orderComment: null,
    stoneId: null,
    brand: null,
  },
  settings: {
    isMobile: false,
    isLoggedIn: false,
    isSelectedStone: false,
    isLoading: false,
    isMobileStones: false,
    isReadOnly: false,
  },
  app: {
    version: `${process.env.REACT_APP_VERSION}`,
    environment: `${process.env.REACT_APP_ENVIRONMENT || 'dev'}`,
  },
  user: {
    brands: [],
  },
  filter: {
    brands: [],
    color: null,
    searchString: '',
  },
  prices: null,
  calc_uid: '',
  calc_report: [],
  offer: [],
};
