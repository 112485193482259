import { FC } from 'react';
import { useFormikContext } from 'formik';
import { TMain } from '../../../types';
import { Paper } from '../../common/paper';
import { Subtitle } from '../controls/subtitle';
import { TopSelector } from '../controls/top-selector';
import { Edges } from './edges';
import { Sizes } from './sizes';
import { Panels } from './pannels';
import { SelectWashing } from '../controls/select-washing';
import { SelectCooking } from '../controls/select-hob-panel';
import { TopLRSelector } from '../controls/top-LR-selector';
import { H } from '../../common/H';
import { TopImage } from './top-image';
import { InfoCard } from './info-card';
import { Switches } from './switches';
import { ProductOptionsMenu } from '../product-options-menu';
import { PreviewReadonly } from './preview';
import { ShowHideTop } from '../controls/show-hide-top';
import { useMobile } from '../../../hooks/useMobile';
import s from './style.module.css';

export const Countertop: FC = () => {
  const formik = useFormikContext<TMain>();
  const { values } = formik;
  const { isMobile, isReadOnly } = values.settings;
  const { isActive } = values.calculator.countertop;

  // reinvoke
  useMobile(formik);

  return (
    <Paper>
      {isReadOnly && (
        <H>
          <Subtitle label="Столешница" />
        </H>
      )}

      {!isReadOnly && (
        <H isMobile={isMobile}>
          <Subtitle label="Основная часть" />
          {isMobile && <ShowHideTop />}
          <TopSelector />
          <TopLRSelector />
          {!isMobile && <ShowHideTop />}
        </H>
      )}

      <div className={s.countertop}>
        <TopImage />
        <div className={s.infoCardOverlay}>
          <InfoCard />
        </div>
        <Panels />
        <Edges />
        <Sizes />
        <Switches />
        {!isMobile && isActive && (
          <>
            <SelectWashing product="countertop" />
            <SelectCooking product="countertop" />
          </>
        )}
      </div>

      {isReadOnly && <PreviewReadonly product="countertop" />}
      {!isReadOnly && <ProductOptionsMenu product="countertop" />}
    </Paper>
  );
};
