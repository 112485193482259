import { FC } from "react";
import { useFormikContext } from "formik";
import deleteImg from "../../../../assets/common/delete.svg";
import { TMain } from "../../../../types";
import s from "./style.module.css";

interface IButtonDelete {
  product: "bar" | "island";
}

export const ButtonDelete: FC<IButtonDelete> = ({ product }) => {
  const { setFieldValue } = useFormikContext<TMain>();

  const deleteProduct = () => setFieldValue(`calculator.${product}`, null);
  return (
    <div className={s.buttonDelete} onClick={deleteProduct}>
      <img src={deleteImg} alt="bar" />
      Удалить
    </div>
  );
};
