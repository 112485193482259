import { useFormikContext } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { api } from '../../../../api/api';
import { IPrice, TMain } from '../../../../types';
import { getMonthWord } from '../../../../utils/getMonthWord';
import { Icon } from '../../../common/icon';
import { Modal } from '../Modal';
import { ReviewsModal } from './ReviewsModal';
import { OrderForm } from './order-form';
import s from './style.module.css';

export interface IReview {
  id: number;

  attributes: {
    author: string; // "Иван"
    content: string; //"Нормально работают"
    createdAt: string; //"2022-04-04T01:21:46.106Z"
    date: string; //"2022-04-01T00:00:00.000Z";
    publishedAt: string; //"2022-04-04T01:21:52.260Z";
    rating: number;
    updatedAt: string; //"2022-04-04T01:21:52.308Z";
  };
}

interface IData {
  data: IReview[];
}

export const PricesOption: FC<{ p: IPrice }> = ({ p }) => {
  const { isMobile, isReadOnly } = useFormikContext<TMain>().values.settings;

  const { worker, price } = p;
  const {
    id,
    name,
    photo,
    review,
    rating,
    production_days,
    description1,
    description2,
  } = worker;
  const reviewDate = review && new Date(review.date);

  const [reviews, setReviews] = useState<IReview[]>();

  useEffect(() => {
    api.reviews({ worker_name: name }).then(({ data }: IData) => {
      setReviews(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalContent, setModalContent] = useState<React.ReactNode | null>(
    null
  );

  const Logo = () => {
    return (
      <div className={s.photoBlock}>
        <img className={s.photo} src={photo} alt="" />
      </div>
    );
  };

  const Rewiew = () => {
    return (
      <div className={s.reviewsContainer}>
        <h4 className={s.name}>{name}</h4>
        {!isReadOnly && review && reviewDate ? (
          <>
            <div className={`stepLabel ${s.textGrey}`}>
              {`${review.author} · ${reviewDate.getDate()} ${getMonthWord(
                reviewDate
              )}`}
            </div>
            <div className={`p14 ${s.reviewContent}`}>{review.content}</div>
            {!isMobile && <OtherReviews />}
          </>
        ) : (
          <>
            {!isReadOnly && <div className={s.textGrey}>Пока нет отзывов</div>}
          </>
        )}
      </div>
    );
  };

  const MakeDate = () => {
    return (
      <div style={{ width: 'max-content' }}>
        <div className={`stepLabel ${s.textGrey}`}>Изготовление</div>
        <h4 className={s.days}>{production_days} дней</h4>

        <div className={s.desc}>{description1}</div>

        <div className={s.desc}>{description2}</div>
      </div>
    );
  };

  const PriceButton = () => {
    return (
      <div
        className={s.priceButton}
        onClick={() =>
          setModalContent(<OrderForm workerName={name} worker_id={id} />)
        }
      >
        {!isReadOnly && (price !== null) && (
          <h3>
            {Intl.NumberFormat('ru-RU', {
              style: 'currency',
              currency: 'RUB',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(price)}
          </h3>
        )}

        <button className={s.price}>
          <h4>Сохранить расчет</h4>
        </button>
      </div>
    );
  };

  const Rating = () => {
    return review ? (
      <>
        <div className={s.rating}>
          {!isMobile && (
            <div className={`stepLabel ${s.textGrey} ${s.ratingLabel}`}>
              Рейтинг
            </div>
          )}
          <div className={s.ratingStar}>
            <Icon img="star" />
            <h4>{rating}</h4>
          </div>
        </div>
      </>
    ) : null;
  };

  const OtherReviews = () =>
    review ? (
      <div
        onClick={() =>
          setModalContent(
            <ReviewsModal worker={worker} price={price} reviews={reviews} />
          )
        }
        className={s.otherReviews}
      >
        + {reviews?.length || 0} Отзывов
      </div>
    ) : null;

  return (
    <>
      <Modal content={modalContent} onClose={() => setModalContent(null)} />
      {isMobile ? (
        <div className={s.container}>
          <div className={s.line}>
            <Rewiew />
            <div className={s.firstLineRight}>
              <Logo />
            </div>
          </div>

          <div className={s.line} style={{ marginTop: '16px' }}>
            <PriceButton />
            <MakeDate />
          </div>
        </div>
      ) : (
        <div className={s.container}>
          <div className={s.left}>
            <Logo />
            <Rewiew />
          </div>
          <div className={s.right}>
            <Rating />
            <MakeDate />
            <PriceButton />
          </div>
        </div>
      )}
    </>
  );
};
