import { FC } from "react";
import close from "../../../../assets/icons/close.svg";
import s from "./style.module.css";

interface IClose {
  onClick: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
}

export const Close: FC<IClose> = ({ onClick, onMouseOver, onMouseLeave }) => {
  return (
    <div
      className={s.close}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <img src={close} alt="x" />
    </div>
  );
};
