import { FC } from 'react';
import { Icon } from '../../../common/icon';
import { InputStep } from '../../controls/input-step';
import s from './style.module.css';

interface IProductItem {
  label: string;
  text: string;
  option: string;
  active?: boolean;
  inputStep?: boolean;
  onClick?: () => void;
}

export const OptionsItem: FC<IProductItem> = ({
  label,
  text,
  option,
  active,
  inputStep = false,
  onClick,
}) => {
  return (
    <div style={{ backgroundImage: `url(${option})` }} className={s.background}>
      <div
        className={`${s.optionsItem} ${active ? s.active : ''}`}
        onClick={() => onClick?.()}
      >
        <div>
          <h4 className={s.h4}>{label}</h4>
          <p>{text}</p>
        </div>
        <div className={s.controls}>
          <Icon img={active ? 'ok-white' : 'plus-blue'} width={44} />
          {inputStep && active && <InputStep name="outlets" />}
        </div>
      </div>
    </div>
  );
};
