import { FC } from "react";
import { useFormikContext } from "formik";
import { barDefault, islandDefault } from "../../../app/defaultValues";
import type { TMain } from "../../../../types";
import { Icon } from "../../../common/icon";
import islandImg from "../../../../assets/product-item/island.png";
import barImg from "../../../../assets/product-item/bar.png";
import s from "./style.module.css";

interface IProductItem {
  label: string;
  text: string;
  product: "bar" | "island";
}

export const ProductItem: FC<IProductItem> = ({ label, text, product }) => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { bar, island } = values.calculator;

  const isProductActive = () => {
    if ((product === "bar" && bar) || (product === "island" && island))
      return true;
    return false;
  };

  const handleClick = () => {
    // барная стойка
    if (product === "bar") {
      if (bar) setFieldValue("calculator.bar", null);
      if (!bar) setFieldValue("calculator.bar", barDefault);
    }

    // остров
    if (product === "island") {
      if (island) setFieldValue("calculator.island", null);
      if (!island) setFieldValue("calculator.island", islandDefault);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${product === "bar" ? barImg : islandImg})`,
      }}
      className={s.background}
    >
      <div
        className={`${s.productItem} ${isProductActive() ? s.active : ""}`}
        onClick={handleClick}
      >
        <div>
          <h4 className={s.header}>{label}</h4>
          <p className={`p14 ${s.text}`}>{text}</p>
        </div>
        <div>
          <Icon img={isProductActive() ? "ok-white" : "plus-blue"} width={44} />
        </div>
      </div>
    </div>
  );
};
