import { FC } from 'react';
import s from './style.module.css';
import minus from '../../../../assets/common/step-minus.svg';
import plus from '../../../../assets/common/step-plus.svg';
import { useFormikContext } from 'formik';
import type { TMain } from '../../../../types';

export const InputStep: FC<{ name: 'outlets' | 'sinkQuantity' }> = ({
  name,
}) => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { outlets } = values.calculator.options;
  const { sinkQuantity } = values.calculator.countertop;

  if (!outlets && name === 'outlets') return null;

  const decrease = () => {
    if (name === 'outlets' && outlets) {
      outlets > 1 && setFieldValue('calculator.options.outlets', outlets - 1);
    }

    if (name === 'sinkQuantity') {
      sinkQuantity > 1 &&
        setFieldValue('calculator.countertop.sinkQuantity', sinkQuantity - 1);
    }
  };

  const increase = () => {
    if (name === 'outlets' && outlets) {
      outlets < 10 && setFieldValue('calculator.options.outlets', outlets + 1);
    }

    if (name === 'sinkQuantity') {
      sinkQuantity < 10 &&
        setFieldValue('calculator.countertop.sinkQuantity', sinkQuantity + 1);
    }
  };

  const showValue = (() => {
    if (name === 'outlets') return outlets;
    if (name === 'sinkQuantity') return sinkQuantity;
  })();

  return (
    <div onClick={(e) => e.stopPropagation()} className={s.inputStep}>
      {name === 'outlets' && (
        <label className={`stepLabel ${s.label}`}>Количество</label>
      )}
      <div className={s.mainBlock}>
        <button onClick={decrease} className={s.minus}>
          <img src={minus} alt="-" />
        </button>
        <h4 className={s.number}>{showValue}</h4>
        <button onClick={increase} className={s.plus}>
          <img src={plus} alt="+" />
        </button>
      </div>
    </div>
  );
};
