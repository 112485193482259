import React from 'react';
import { Paper, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { TMain } from '../../../../types';

export const InfoCard: React.FC = () => {
  const formik = useFormikContext<TMain>();
  const { values } = formik;
  const { isMobile, isReadOnly } = values.settings;
  return (
    <Paper sx={{ backgroundColor: '#3c41d6' }} id="info-card">
      {isMobile ? (
        <Typography variant="body1" align="center" color="white" fontSize="0.8rem" padding="4px">
          Размеры по стенам с каждой стороны, не вырезая на 60.
        </Typography>
      ) : (
        <Typography variant="body1" align="center" color="white" fontSize="0.9rem" padding="12px">
          Размеры столешницы надо указывать по стенам с каждой стороны, не вырезая глубину на 60.
          Калькулятор автоматически сам кроит на детали.
        </Typography>
      )}
    </Paper>
  );
};
