import { useFormikContext } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { api } from '../../../../api/api';
import { IPrice, TMain } from '../../../../types';
import { NO_PRICE_REASON_TYPE_MAP } from '../../../../constants';
import { getMonthWord } from '../../../../utils/getMonthWord';
import { Icon } from '../../../common/icon';
import { Modal } from '../Modal';
import { ReviewsModal } from './ReviewsModal';
import { OrderForm } from './order-form';
import s from './style.module.css';

export interface IReview {
  id: number;

  attributes: {
    author: string; // "Иван"
    content: string; //"Нормально работают"
    createdAt: string; //"2022-04-04T01:21:46.106Z"
    date: string; //"2022-04-01T00:00:00.000Z";
    publishedAt: string; //"2022-04-04T01:21:52.260Z";
    rating: number;
    updatedAt: string; //"2022-04-04T01:21:52.308Z";
  };
}

interface IData {
  data: IReview[];
}

export const PricesOptionPrivate: FC<{ p: IPrice }> = ({ p }) => {
  const { values } = useFormikContext<TMain>();

  const { settings, calc_report, offer } = values;

  const { isMobile, isReadOnly, isLoggedIn } = settings;

  const { worker, price, currency, no_price_reason } = p;
  const {
    id,
    name,
    photo,
    review,
    rating,
    production_days,
    description1,
    description2,
  } = worker;

  const currentWorkerUrl = calc_report?.find((report) => +report.id === id);

  const reviewDate = review && new Date(review.date);

  const [reviews, setReviews] = useState<IReview[]>();
  const { pathname } = useLocation();
  const isOffer = pathname === '/offer';

  const currentOffer = offer.find((ofr) => ofr.worker_id === id);

  let no_price_reason_message: string | null = null;
  if (no_price_reason !== null) {
    if (no_price_reason.type in NO_PRICE_REASON_TYPE_MAP) {
      no_price_reason_message = NO_PRICE_REASON_TYPE_MAP[no_price_reason.type];
    } else {
      no_price_reason_message = no_price_reason.message;
    }
  }

  useEffect(() => {
    api.reviews({ worker_name: name }).then(({ data }: IData) => {
      setReviews(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalContent, setModalContent] = useState<React.ReactNode | null>(
    null
  );

  const Logo = () => {
    return (
      <div className={s.photoBlock}>
        <img className={s.photo} src={photo} alt="" />
      </div>
    );
  };

  const Rewiew = () => {
    return (
      <div className={s.reviewsContainer}>
        <h4 className={s.name}>{name}</h4>
        {!isReadOnly && review && reviewDate ? (
          <>
            <div className={`stepLabel ${s.textGrey}`}>
              {`${review.author} · ${reviewDate.getDate()} ${getMonthWord(
                reviewDate
              )}`}
            </div>
            <div className={`p14 ${s.reviewContent}`}>{review.content}</div>
            {!isMobile && <OtherReviews />}
          </>
        ) : (
          <>
            {!isReadOnly && <div className={s.textGrey}>Пока нет отзывов</div>}
          </>
        )}
      </div>
    );
  };

  const MakeDate = () => {
    if (production_days && price !== null) {
      return (
        <div style={{ width: 'max-content', paddingLeft: '16px' }}>
          <div className={`stepLabel ${s.textGrey}`}>Изготовление</div>
          <h4 className={s.days}>{production_days} дней</h4>
          <div className={s.desc}>{description1}</div>
          <div className={s.desc}>{description2}</div>
        </div>
      );
    } else {
      return (
        <div style={{ width: 'max-content', paddingLeft: '16px' }}>
          <div className={s.desc}>{description1}</div>
          <div className={s.desc}>{description2}</div>
        </div>
      );
    }
  };

  const PriceButton = () => {
    return (
      <div
        className={s.priceButton}
        onClick={() => {
          if (currentOffer) {
            window.open(currentOffer.offer, '_blank');
          }
          if (!currentOffer) {
            setModalContent(<OrderForm workerName={name} worker_id={id} />);
          }
        }}
      >
        {(!isReadOnly || isLoggedIn || isOffer) && price !== null && (
          <h3>
            {Intl.NumberFormat('ru-RU', {
              style: 'currency',
              currency: 'RUB',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(price)}
          </h3>
        )}
      </div>
    );
  };

  const OfferButton = () => {
    return (
      <div
        className={s.priceButton}
        onClick={() => {
          if (currentOffer) {
            window.open(currentOffer.offer, '_blank');
          }
          if (!currentOffer) {
            setModalContent(<OrderForm workerName={name} worker_id={id} />);
          }
        }}
      >
        {price !== null && (
          <button className={s.price}>
            <h4>
              {isLoggedIn
                ? currentOffer
                  ? 'КП'
                  : 'Создать КП'
                : 'Сохранить расчет'}
            </h4>
          </button>
        )}

        {price === null && no_price_reason_message !== null && (
          <p>{no_price_reason_message}</p>
        )}
      </div>
    );
  };

  const ReportButton = () => {
    if (isOffer) return null;
    if (calc_report.length > 0) {
      return (
        <div
          className={s.priceButton}
          onClick={() => window.open(currentWorkerUrl?.report_url, '_blank')}
        >
          <button className={s.price}>
            <h4>Расчет</h4>
          </button>
        </div>
      );
    } else {
      return (
        <div className={s.priceButton}>
          <button className={s.price} disabled={true}>
            <h4>загрузка...</h4>
          </button>
        </div>
      );
    }
  };

  const Rating = () => {
    return review && rating ? (
      <>
        <div className={s.rating}>
          {!isMobile && (
            <div className={`stepLabel ${s.textGrey} ${s.ratingLabel}`}>
              Рейтинг
            </div>
          )}
          <div className={s.ratingStar}>
            <Icon img="star" />
            <h4>{rating}</h4>
          </div>
        </div>
      </>
    ) : null;
  };

  const OtherReviews = () =>
    review ? (
      <div
        onClick={() =>
          setModalContent(
            <ReviewsModal worker={worker} price={price} reviews={reviews} />
          )
        }
        className={s.otherReviews}
      >
        + {reviews?.length || 0} Отзывов
      </div>
    ) : null;

  return (
    <>
      <Modal content={modalContent} onClose={() => setModalContent(null)} />
      {isMobile ? (
        <div className={s.container}>
          <div className={s.line}>
            {!isLoggedIn && <Rewiew />}
            <div className={s.firstLineRight}>
              <Logo />
            </div>
            {isLoggedIn && (
              <div>
                {(calc_report.length === 0 || currentWorkerUrl?.report_url) && (
                  <ReportButton />
                )}
                <OfferButton />
              </div>
            )}
          </div>

          <div
            className={s.line}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <MakeDate />
            <PriceButton />
          </div>
        </div>
      ) : (
        <div className={s.container}>
          <div className={s.left}>
            <Logo />
            {!isLoggedIn && <Rewiew />}
          </div>

          <div className={s.right}>
            {!isMobile && <Rating />}

            <MakeDate />
            <div style={{ flexGrow: 1 }}></div>
            <PriceButton />
            {(calc_report.length === 0 || currentWorkerUrl?.report_url) && (
              <ReportButton />
            )}
            {!isOffer && <OfferButton />}
          </div>
        </div>
      )}
    </>
  );
};
