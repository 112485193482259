import { FC } from 'react';
import { useFormikContext } from 'formik';
import countertopP from '../../../../assets/countertop/countertop-p.png';
import countertopPempty from '../../../../assets/countertop/countertop-p-empty.png';
import countertopL from '../../../../assets/countertop/countertop-l.png';
import countertopLempty from '../../../../assets/countertop/countertop-l-empty.png';
import countertopR from '../../../../assets/countertop/countertop-r.png';
import countertopRempty from '../../../../assets/countertop/countertop-r-empty.png';
import countertopQ from '../../../../assets/countertop/countertop-q.png';
import countertopQempty from '../../../../assets/countertop/countertop-q-empty.png';
import { TMain } from '../../../../types';
import s from './style.module.css';

export const TopImage: FC = () => {
  const { values } = useFormikContext<TMain>();
  const { type, isActive } = values.calculator.countertop;

  if (type === 'L') {
    if (isActive) return <img src={countertopL} className={s.ctopImg} alt="" />;
    return <img src={countertopLempty} className={s.ctopImg} alt="" />;
  }

  if (type === 'R') {
    if (isActive) return <img src={countertopR} className={s.ctopImg} alt="" />;
    return <img src={countertopRempty} className={s.ctopImg} alt="" />;
  }

  if (type === 'U') {
    if (isActive) return <img src={countertopP} className={s.ctopImg} alt="" />;
    return <img src={countertopPempty} className={s.ctopImg} alt="" />;
  }

  if (type === 'Q') {
    if (isActive) return <img src={countertopQ} className={s.ctopImg} alt="" />;
    return <img src={countertopQempty} className={s.ctopImg} alt="" />;
  }
  return null;
};
