import { FC } from "react";
import { useFormikContext } from "formik";
import { TMain } from "../../../types";
import { OptionsItem } from "./options-item";
import outlets from "../../../assets/options-item/outlets.png";
import radiator from "../../../assets/options-item/radiator.png";
import sink from "../../../assets/options-item/sink.png";
import stand from "../../../assets/options-item/stand.png";
import s from "./style.module.css";

export const OptionsMenu: FC = () => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { isMobile } = useFormikContext<TMain>().values.settings;

  return (
    <div className={s.container}>
      <OptionsItem
        label="Вырезы под смеситель/дозатор/розетки"
        text="дополнительные отверстия в столешнице"
        option={outlets}
        onClick={() => {
          if (!values.calculator.options.outlets) {
            setFieldValue("calculator.options.outlets", 1);
          } else {
            setFieldValue("calculator.options.outlets", null);
          }
        }}
        active={!!values.calculator.options.outlets}
        inputStep
      />

      <OptionsItem
        label={
          isMobile
            ? "Вентиляционные решетки"
            : "Вентиляционные решетки для батареи"
        }
        text="сохраняют от конденсата и плесени"
        onClick={() =>
          setFieldValue(
            "calculator.options.ventHoles",
            !values.calculator.options.ventHoles
          )
        }
        active={!!values.calculator.options.ventHoles}
        option={radiator}
      />

      <OptionsItem
        label="Проточки для стока воды"
        text="устраняют скапливание влаги в районе мойки"
        option={sink}
        onClick={() =>
          setFieldValue(
            "calculator.options.drainGroves",
            !values.calculator.options.drainGroves
          )
        }
        active={!!values.calculator.options.drainGroves}
      />

      <OptionsItem
        label="Подставка под горячее"
        text="защищает от перепадов температур"
        option={stand}
        onClick={() =>
          setFieldValue(
            "calculator.options.hotRods",
            !values.calculator.options.hotRods
          )
        }
        active={!!values.calculator.options.hotRods}
      />
    </div>
  );
};
