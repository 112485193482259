import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { TMain } from '../../../../types';
import toggleActive from '../../../../assets/top-toggle/active.svg';
import toggleInactive from '../../../../assets/top-toggle/inactive.svg';
import s from './style.module.css';

export const ShowHideTop: FC = () => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { isActive } = values.calculator.countertop;
  const { isMobile } = values.settings;

  return (
    <div
      className={isActive ? s.toggle : s.toggleMobile}
      onClick={() => setFieldValue('calculator.countertop.isActive', !isActive)}
    >
      {isMobile && <div>Скрыть</div>}
      <img
        className={isMobile ? s.toggleMobileImg : s.toggleImg}
        src={isActive ? toggleActive : toggleInactive}
        alt="v"
      />
      {!isMobile && <div>Скрыть столешницу</div>}
    </div>
  );
};
