import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { TMain } from '../../../../types';
import { countertopL, countertopR } from '../../../app/defaultValues';
import rImg from '../../../../assets/icons/r-top.svg';
import rImgBlack from '../../../../assets/icons/r-top-black.svg';
import lImg from '../../../../assets/icons/l-top.svg';
import lImgBlack from '../../../../assets/icons/l-top-black.svg';
import s from './style.module.css';

type TActiveTab = 'L' | 'R';

export const TopLRSelector: FC = () => {
  const { values, setFieldValue } = useFormikContext<TMain>();

  const isLeft = values.calculator.countertop.type === 'L';
  const isRight = values.calculator.countertop.type === 'R';
  const [activeTab, setActiveTab] = useState<TActiveTab>('L');

  useEffect(() => {
    setActiveTab((values.calculator.countertop.type as 'L') || 'R');
  }, [values.calculator.countertop.type]);

  if (!isLeft && !isRight) return null;

  return (
    <div className={s.typeSelector}>
      <button
        onClick={() => {
          setActiveTab('L');
          setFieldValue('calculator.countertop', countertopL);
        }}
        className={`pm14 ${s.button} ${activeTab === 'L' ? s.active : ''}`}
      >
        <img src={activeTab === 'L' ? lImg : lImgBlack} alt="L" />
      </button>

      <button
        onClick={() => {
          setActiveTab('R');
          setFieldValue('calculator.countertop', countertopR);
        }}
        className={`${s.button} ${activeTab === 'R' ? s.active : ''}`}
      >
        <img src={activeTab === 'R' ? rImg : rImgBlack} alt="R" />
      </button>
    </div>
  );
};
