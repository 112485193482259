import { FC } from "react";
import { useFormikContext } from "formik";
import { TMain } from "../../../../types";
import { TStone } from "../../../../hooks/useStones";
import { Icon } from "../../../common/icon";
import s from "./style.module.css";

export const Stones: FC<{ stones: TStone[] }> = ({ stones }) => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { stoneId } = values.calculator;

  return (
    <>
      <div className={s.stonesList}>
        {stones.map(({ id, attributes }) => {
          const name = attributes?.name || "???";
          const uid = attributes?.uid || "";
          const price = attributes.price_m_rub || "";
          const { formats } = attributes?.photo?.data?.attributes || {};
          const photo = formats?.small?.url || formats?.thumbnail?.url || null;

          return (
            <div
              className={s.stoneBlock}
              key={`stone_${id}`}
              onClick={() => {
                setFieldValue("calculator.stoneId", stoneId === id ? null : id);
                setFieldValue("calculator.material.uid", uid);
                setFieldValue("settings.isSelectedStone", true);
              }}
            >
              <div className={s.imgBlock}>
                <div
                  className={s.circle}
                  style={{
                    background: `url(${photo})`,
                    backgroundPosition: "98%",
                    backgroundSize: "200%",
                  }}
                />
                {stoneId === id && (
                  <Icon className={s.ok} img="ok-blue" width={44} />
                )}
              </div>

              <div className="stoneName">{name}</div>
              <div className="stonePrice">{`от ${price}₽ за м2`}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};
