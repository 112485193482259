import { FC } from "react";
import { useFormikContext } from "formik";
import { TMain } from "../../../../../types";
import "./style.css";

interface ILine {
  type: "edge" | "plinth" | null;
  position:
    | "innerLeft"
    | "outerLeft"
    | "innerMiddle"
    | "outerMiddle"
    | "innerRight"
    | "outerRight"
    | "outerBottom"
    | "outerLeftBottom"
    | "outerRightBottom";
}

export const Line: FC<ILine> = ({ type, position }) => {
  const { values } = useFormikContext<TMain>();

  const { type: countertopType } = values.calculator.countertop;

  const cn = type ? type : "dotted";

  return <div className={`line c-${countertopType}-${position} ${cn}`}></div>;
};
