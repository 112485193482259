import React, { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { TMain, TPanelsPosition } from '../../../../types';
import { SizeControl } from '../size-control';
import s from './style.module.css';

type TPanelsRange = {
  range: {
    height: {
      min: number;
      max: number;
    };
    width: {
      min: number;
      max: number;
    };
  };
};

export const PanelSizes: FC<TPanelsRange> = ({ range }) => {
  const { values } = useFormikContext<TMain>();
  const { type, panels } = values.calculator.countertop;

  const [panelsState, setPanelsState] = useState(panels);

  useEffect(() => {
    setPanelsState(panels);
  }, [panels]);

  if (!panels.length) return null;

  const getIdByName = (name: TPanelsPosition) =>
    panels.map((p) => p.name).indexOf(name);

  return (
    <>
      {panelsState
        .filter(({ isActive }) => isActive)
        .map(({ name: pannelName }, i) => (
          <div
            key={pannelName}
            className={`${s.panelSizes} ${s[pannelName]} ${s[type]}`}
          >
            <div className={s.panelHeight}>
              <span className={s.label}>В</span>
              <SizeControl
                maxLength={4}
                fieldName={`calculator.countertop.panels[${getIdByName(
                  pannelName
                )}].height`}
                range={{ min: range.height.min, max: range.height.max }}
                s={s}
              />
            </div>

            <div className={s.panelWidth}>
              <span className={s.label}>Д</span>
              <SizeControl
                maxLength={5}
                fieldName={`calculator.countertop.panels[${getIdByName(
                  pannelName
                )}].width`}
                range={{ min: range.width.min, max: range.width.max }}
                s={s}
              />
            </div>
          </div>
        ))}
    </>
  );
};
