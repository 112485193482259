import { useFormikContext } from 'formik';
import { FC } from 'react';
import { Paper } from '../../common/paper';
import { ButtonDelete } from '../controls/button-delete';
import { Subtitle } from '../controls/subtitle';
import { ProductOptionsMenu } from '../product-options-menu';
import { TMain } from '../../../types';
import barImg from '../../../assets/bar-counter/bar-counter.png';
import { Layer } from '../../common/layer';
import { SizeControl } from '../controls/size-control';
import s from './style.module.css';

export const Bar: FC = () => {
  const {
    calculator: { bar },
    settings: { isReadOnly },
  } = useFormikContext<TMain>().values;
  if (!bar) return null;

  return (
    <Paper>
      <div className={s.header}>
        <Subtitle label="Барная стойка" />
        {!isReadOnly && <ButtonDelete product="bar" />}
      </div>

      <div className={s.bar}>
        <img src={barImg} alt="" style={{ maxWidth: '100%' }} />

        <Layer>
          <SizeControl
            fieldName="calculator.bar.sizes.length"
            position={{ top: 18, left: 50 }}
            maxLength={4}
            range={{ min: 60, max: 300 }}
          />
          <SizeControl
            fieldName="calculator.bar.sizes.width"
            position={{ top: 42, left: 13 }}
            range={{ min: 40, max: 300 }}
          />

          <div className={s.tooltip}>
            <span className={s.tooltiptext}>Кромка</span>K
          </div>
        </Layer>
      </div>

      <ProductOptionsMenu product="bar" />
    </Paper>
  );
};
