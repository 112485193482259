import { FC } from "react";
import { useFormikContext } from "formik";
import { TCountertop, TMain } from "../../../../types";
import { sinks } from "../../controls/select-washing/dictionary";
import { edges } from "../../controls/select-front-edge/dictionary";
import s from "./style.module.css";

export const PreviewReadonly: FC<{ product: "countertop" | "island" }> = ({
  product,
}) => {
  const { sink, edgeType } = useFormikContext<TMain>().values.calculator[
    product
  ] as TCountertop;

  const currentSink = sinks.find((sk) => sk.title === sink);
  const currentEdge = edges.find((eg) => eg.title === edgeType);

  return (
    <div className={s.previewReadOnly}>
      {currentSink && (
        <div>
          <p>{currentSink?.title}</p>
          <img width={60} src={currentSink?.img} alt="" />
        </div>
      )}

      {currentEdge && (
        <div>
          <p>{currentEdge?.title}</p>
          <img src={currentEdge?.img} alt="" />
        </div>
      )}
    </div>
  );
};
