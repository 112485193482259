import { FC } from 'react';
import { Countertop } from './countertop';
import { OrderList } from './order-list';
import { ProductMenu } from './product-menu';
import { OptionsMenu } from './options-menu';
import { MainGrid } from '../common/grid';
import { Title } from './controls/title';
import { Materials } from './materials';
import { Island } from './island';
import { Bar } from './bar';
import { Subhead } from './controls/Subhead';
import { PricesList } from './prices';
import { useFormikContext } from 'formik';
import { TMain } from '../../types';
import { ErrorMessage } from './errorMessage';
import { useLocation } from 'react-router-dom';
import { PUBLIC_CALC_ROUTES } from '../../constants';

export const Calculator: FC = () => {
  const {
    settings: { isMobile, isLoggedIn },
    prices,
    error,
  } = useFormikContext<TMain>().values;

  const { pathname } = useLocation();

  if (!PUBLIC_CALC_ROUTES.includes(pathname) && !isLoggedIn) {
    return (
      <div
        style={{
          height: 'calc(100vh - 74px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '5%',
        }}
      >
        <h2>Войдите в личный кабинет, чтобы начать расчет.</h2>
      </div>
    );
  }

  return (
    <MainGrid
      title={<Title label="Калькулятор столешниц" />}
      main={
        <>
          <Countertop />
          <ProductMenu />
          <Bar />
          <Island />
          <Subhead label="Опции для комфорта и удовольствия" />
          <OptionsMenu />
          <Subhead label="Материалы и цвет" />
          <Materials />
          {error && <ErrorMessage message={error} />}
          {prices && <PricesList />}
          {isMobile && <OrderList />}
        </>
      }
      right={<OrderList />}
    />
  );
};
