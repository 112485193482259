import { FC } from "react";
import plusBlue from "../../../assets/icons/plus-blue.svg";
import plusGreen from "../../../assets/icons/plus-green.svg";
import okBlue from "../../../assets/icons/ok-blue.svg";
import okGreen from "../../../assets/icons/ok-green.svg";
import okWhite from "../../../assets/icons/ok-white.svg";
import left from "../../../assets/icons/pagination-left.svg";
import right from "../../../assets/icons/pagination-right.svg";
import close from "../../../assets/icons/close.svg";
import arrowUpWhite from "../../../assets/icons/arrow-up-white.svg";
import arrowDownWhite from "../../../assets/icons/arrow-down-white.svg";
import arrowUpBlue from "../../../assets/icons/arrow-up-blue.svg";
import arrowDownBlack from "../../../assets/icons/arrow-down-black.svg";
import k from "../../../assets/icons/k.svg";
import kBlue from "../../../assets/icons/k-blue.svg";
import b from "../../../assets/icons/b.svg";
import bGreen from "../../../assets/icons/b-green.svg";
import star from "../../../assets/icons/star.svg";
import edit from "../../../assets/icons/edit.svg";
import reviewsRightArrow from "../../../assets/icons/reviewsRightArrow.svg";
import reviewsLeftArrow from "../../../assets/icons/reviewsLeftArrow.svg";

export type TImg =
  | "k"
  | "b"
  | "plus-blue"
  | "plus-green"
  | "plus-white"
  | "ok-blue"
  | "ok-green"
  | "ok-white"
  | "left"
  | "right"
  | "close"
  | "pagination-left"
  | "pagination-right"
  | "arrow-up-white"
  | "arrow-down-white"
  | "arrow-up-blue"
  | "arrow-down-black"
  | "star"
  | "edit"
  | "reviews-right-arrow"
  | "reviews-left-arrow"
  | "k-blue"
  | "b-green";

interface IIcon {
  img: TImg;
  width?: number;
  className?: string;
}

export const Icon: FC<IIcon> = ({ img, width = "auto", className = "" }) => {
  const getSrc = (img: TImg) => {
    if (img === "ok-blue") return okBlue;
    if (img === "ok-green") return okGreen;
    if (img === "ok-white") return okWhite;
    if (img === "plus-blue") return plusBlue;
    if (img === "plus-white") return plusGreen;
    if (img === "plus-green") return plusGreen;
    if (img === "left") return left;
    if (img === "right") return right;
    if (img === "close") return close;
    if (img === "arrow-up-white") return arrowUpWhite;
    if (img === "arrow-down-white") return arrowDownWhite;
    if (img === "arrow-up-blue") return arrowUpBlue;
    if (img === "arrow-down-black") return arrowDownBlack;
    if (img === "k") return k;
    if (img === "b") return b;
    if (img === "star") return star;
    if (img === "edit") return edit;
    if (img === "reviews-right-arrow") return reviewsRightArrow;
    if (img === "reviews-left-arrow") return reviewsLeftArrow;
    if (img === "k-blue") return kBlue;
    if (img === "b-green") return bGreen;
  };
  return (
    <img
      className={className}
      src={getSrc(img)}
      alt={img}
      style={{ width: `${width}px`, height: `${width}px` }}
    />
  );
};
