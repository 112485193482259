import { FC } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { KP } from '../calculator/kp';
import { Calculator } from '../calculator';
import { TMain } from '../../types';
import { initialValues } from './initialValues';
import { useMobile } from '../../hooks/useMobile';
import { Header } from '../header/Header';
import { FooterMini } from '../landing/footer-mini';
import { Landing } from '../landing';
import './inter.css';
import { LoginBar, DemoBar } from '../login';

export const App: FC = () => {
  const formik = useFormik<TMain>({
    initialValues,
    validateOnChange: false,
    validationSchema: null,
    onSubmit: () => new Promise(() => null),
  });

  useMobile(formik);

  return (
    <BrowserRouter>
      <FormikProvider value={formik}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                {/*<Header />*/}
                {/*<Calculator />*/}
                {/*<Footer />  */}
                <LoginBar />
                <Calculator />
                <FooterMini />
              </>
            }
          />
          {/*<Route*/}
          {/*  path="/partners"*/}
          {/*  element={*/}
          {/*    <>*/}
          {/*      <Landing />*/}
          {/*    </>*/}
          {/*  }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/calc"*/}
          {/*  element={*/}
          {/*    <>*/}
          {/*      <LoginBar />*/}
          {/*      <Calculator />*/}
          {/*    </>*/}
          {/*  }*/}
          {/*/>*/}
          <Route
            path="/demo"
            element={
              <>
                <DemoBar />
                <Calculator />
              </>
            }
          />
          <Route path="/offer" element={<KP />} />
        </Routes>
      </FormikProvider>
    </BrowserRouter>
  );
};
