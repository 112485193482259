import { useFormikContext } from "formik";
import { FC } from "react";
import { TMain } from "../../../../types";
import s from "./style.module.css";

export const MaterialSelect: FC = () => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { type } = values.calculator.material;

  return (
    <div className={s.typeSelector}>
      <button
        onClick={() => setFieldValue("calculator.material.type", "acryl")}
        className={`${s.button} ${type === "acryl" ? s.active : ""}`}
      >
        Акрил
      </button>
      <button
        onClick={() => setFieldValue("calculator.material.type", "quartz")}
        className={`${s.button} ${type === "quartz" ? s.active : ""}`}
      >
        Кварц
      </button>
    </div>
  );
};
