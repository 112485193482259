import { useFormikContext } from 'formik';
import { FC } from 'react';
import { TMain } from '../../../../types';
import { PanelShowHide } from '../../controls/pannel-show-hide';
import { Pannel } from './panel';

export const Panels: FC = () => {
  const { panels } = useFormikContext<TMain>().values.calculator.countertop;
  if (!panels.length) return null;

  return (
    <>
      <PanelShowHide />
      <Pannel />
    </>
  );
};
