import { useFormikContext } from 'formik';
import { FC } from 'react';
import { TMain } from '../../../../types';
import { Icon } from '../../../common/icon';
import s from './style.module.css';

export const ShowMain: FC = () => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const { isReadOnly } = values.settings;

  if (isReadOnly) return null;

  return (
    <div
      onClick={() => setFieldValue('calculator.countertop.isActive', true)}
      className={s.showMain}
    >
      <Icon img="plus-blue" width={35} />
      <div>Показать столешницу</div>
    </div>
  );
};
