import { FC } from 'react';
import { useFormikContext } from 'formik';
import { TMain } from '../../../../types';
import { ButtonSwitch } from '../../controls/button-switch';

export const Switches: FC = () => {
  const { values } = useFormikContext<TMain>();
  const { type, isActive } = values.calculator.countertop;

  if (!isActive) return null;

  return (
    <>
      {type === 'Q' && (
        <>
          <ButtonSwitch position="edge1" />
          <ButtonSwitch position="edge2" />
          <ButtonSwitch position="edge3" />
          <ButtonSwitch position="edge4" />
        </>
      )}

      {(type === 'L' || type === 'R') && (
        <>
          <ButtonSwitch position="edge1" />
          <ButtonSwitch position="edge2" />
          <ButtonSwitch position="edge3" />
          <ButtonSwitch position="edge4" />
          <ButtonSwitch position="edge5" />
          <ButtonSwitch position="edge6" />
        </>
      )}

      {type === 'U' && (
        <>
          <ButtonSwitch position="edge1" />
          <ButtonSwitch position="edge2" />
          <ButtonSwitch position="edge3" />
          <ButtonSwitch position="edge4" />
          <ButtonSwitch position="edge5" />
          <ButtonSwitch position="edge6" />
          <ButtonSwitch position="edge7" />
          <ButtonSwitch position="edge8" />
        </>
      )}
    </>
  );
};
