import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { TMain } from '../../../types';
import { sinks } from '../controls/select-washing/dictionary';
import { api } from '../../../api/api';
import { Icon } from '../../common/icon';
import { AdditionalComments } from './additional-comments';
import s from './style.module.css';

export const OrderList: FC<{ isHandlersPage?: boolean }> = ({
  isHandlersPage = false,
}) => {
  const { values, setFieldValue } = useFormikContext<TMain>();
  const {
    calculator: { countertop, options, material },
    prices,
    settings: { isReadOnly, isLoggedIn },
  } = values;
  const { type, sinkQuantity, isActive } = countertop;

  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [credentials, setCredentials] = useState<{
    login: string;
    password: string;
  } | null>(null);

  useEffect(() => {
    if (isLoggedIn) {
      setCredentials({
        login: localStorage.getItem('login') || '',
        password: localStorage.getItem('password') || '',
      });
    } else {
      setCredentials(null);
    }
  }, [isLoggedIn]);

  const getCounterTopName = () => {
    if (type === 'Q') return 'Прямая столешница';
    if (type === 'L') return 'Г-образная столешница';
    if (type === 'R') return 'Г-образная столешница';
    if (type === 'U') return 'П-образная столешница';
    return 'ошибка типа';
  };

  const bar = () => {
    const bar = values.calculator?.bar;

    if (!bar) return null;

    const {
      sizes: { width, length },
      edgeType,
    } = bar;

    return (
      <>
        <h4>Барная стойка</h4>
        <ul>
          <li>
            {length}см х {width}см
          </li>

          <li>Кромка: {edgeType}</li>
        </ul>
      </>
    );
  };

  const island = () => {
    const island = values.calculator?.island;

    if (!island) return null;

    const {
      sizes: { length, width, height, thickness },
      edgeType,
    } = island;

    return (
      <>
        <h4>Остров</h4>
        <ul>
          <li>{`${length}см х ${width}см х ${thickness}см `}</li>
          <li>{`Высота острова: ${height}см`}</li>
          <li>Кромка: {edgeType}</li>
          {island.sink ? (
            <li>
              {sinks.find((sn) => sn.title === island.sink)?.title}{' '}
              {sinkQuantity} шт.
            </li>
          ) : (
            <li>Без мойки</li>
          )}

          {island.hob ? (
            <li>Вырез под варочную панель</li>
          ) : (
            <li>Без варочной панели</li>
          )}
        </ul>

        {/* <ul>
          <li>
            Стены:
            <br /> 1: {edge1 === "edge" ? "кромка" : "опора"}
            <br /> 2: {edge2 === "edge" ? "кромка" : "опора"}
            <br /> 3: {edge3 === "edge" ? "кромка" : "опора"}
            <br />
            4: {edge4 === "edge" ? "кромка" : "опора"}
          </li>
        </ul> */}
      </>
    );
  };

  const parseUid = (uid: string | null) => {
    if (!uid) return '';
    const [material, brand, code] = uid.split(':');

    return (
      <>
        <li>{`Материал: ${
          material === 'acryl' ? 'Акрил' : 'Кварц'
        } ${brand} ${code}`}</li>
      </>
    );
  };

  const checkSinkAndEdge = () => {
    const currentSink = values.calculator.countertop.sink;
    const currentEdge = values.calculator.countertop.edgeType;
    const isQuarz = values.calculator.material.type === 'quartz';
    if (
      [
        'Двойная мойка',
        'Овальная мойка',
        'Круглая мойка',
        'Полукруглая мойка',
        'Прямоугольная мойка',
      ].find((m) => m === currentSink) &&
      isQuarz
    ) {
      setFieldValue('calculator.countertop.sink', 'Квадратная мойка');
    }

    // if (
    //   ["Прямая 1", "Прямая 2", "Прямая 3", "Прямая 4"].find(
    //     (m) => m === currentEdge
    //   ) &&
    //   isQuarz
    // ) {
    //   setFieldValue("calculator.countertop.edgeType", "Прямая 5");
    // }

    if (
      [
        'Прямая 5',
        'Фигурная 1',
        'Фигурная 2',
        'Фигурная 3',
        'Фигурная 4',
        'Фигурная 5',
      ].find((m) => m === currentEdge) &&
      !isQuarz
    ) {
      setFieldValue('calculator.countertop.edgeType', 'Прямая 1');
    }
  };

  const handleButtonClick = async () => {
    setLoading(true);

    try {
      setFieldValue('calc_report', []);

      const { data: priceData } = await api.calcPrice(
        values.calculator,
        credentials,
        values.app.version,
        values.app.environment
      );

      if (priceData.errorMessage) {
        setFieldValue('error', priceData.errorMessage);
        return;
      }

      setFieldValue('prices', priceData.data);
      setFieldValue('calc_uid', priceData.calc_uid);

      if (credentials && priceData.data.length > 0 && isLoggedIn) {
        const { data: reportData } = await api.generateReport(
          priceData.calc_uid,
          credentials
        );

        if (reportData.errorMessage) {
          setFieldValue('error', reportData.errorMessage);
        } else if (reportData) {
          const arr = [];

          for (const key in reportData) {
            arr.push({ id: key, ...reportData[key] });
          }

          setFieldValue('calc_report', arr);
        }
      }
    } catch (error) {
      // Handle any unexpected errors here
      console.error('An error occurred:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className={`${s.orderBlock} ${
          isHandlersPage ? s.orderBlockHandlers : ''
        }`}
      >
        <div className={s.orderList}>
          {isActive && (
            <>
              <h4>{getCounterTopName()}</h4>
              <ul className="pm14">
                <li>
                  {Object.values(countertop.sizes).map((v, i) => {
                    if (
                      v > 0 &&
                      i !== Object.values(countertop.sizes).length - 1
                    ) {
                      return v && `${v}см х `;
                    }
                    if (v !== 0) {
                      return `${v}см`;
                    }

                    return '';
                  })}
                </li>

                {parseUid(material.uid)}

                {countertop.sink ? (
                  <li>
                    {sinks.find((sn) => sn.title === countertop.sink)?.title}{' '}
                    {sinkQuantity} шт.
                  </li>
                ) : (
                  <li>Без мойки</li>
                )}

                {countertop.hob ? (
                  <li>Вырез под варочную панель</li>
                ) : (
                  <li>Без варочной панели</li>
                )}

                <li>Кромка: {countertop.edgeType}</li>

                {Object.values(countertop.edges).includes('plinth') && (
                  <li>С бортиком</li>
                )}
              </ul>
            </>
          )}

          {(options.outlets ||
            options.ventHoles ||
            options.drainGroves ||
            options.hotRods) && (
            <>
              <h4>Опции:</h4>
              <ul>
                {options.outlets && (
                  <li>Дополнительные вырезы: {options.outlets}</li>
                )}
                {options.ventHoles && (
                  <li>Вентиляционные решетки для батареи</li>
                )}
                {options.drainGroves && <li>Проточки для стока воды</li>}
                {options.hotRods && <li>Подставки под горячее</li>}
              </ul>
            </>
          )}

          {countertop.panels.length > 0 &&
            !!countertop.panels.find(({ isActive }) => isActive) && (
              <>
                <h4>Панели</h4>
                <ul>
                  {countertop.panels.map(
                    ({ isActive, width, height, name }, i) =>
                      isActive && (
                        <li key={name}>{`[${
                          i + 1
                        }] ${width}cм х ${height}cм`}</li>
                      )
                  )}
                </ul>
              </>
            )}

          {bar()}
          {island()}
        </div>

        <AdditionalComments
          readOnly={isReadOnly || isHandlersPage}
          showForm={showForm}
          setShowForm={setShowForm}
        />
        {!isReadOnly && !isHandlersPage && (
          <button onClick={() => setShowForm(true)} className={s.addButton}>
            <Icon img="plus-blue" width={30} />
          </button>
        )}

        {!prices && !isReadOnly && (
          <button
            className={s.buttonCalc}
            disabled={loading}
            onMouseOver={checkSinkAndEdge}
            onClick={handleButtonClick}
          >
            {loading ? (
              <div className={s.ldsRing}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <h4>Рассчитать</h4>
            )}
          </button>
        )}

        {prices && !isReadOnly && (
          <div className={s.buttonCalc}>
            <button
              className={s.buttonEdit}
              onClick={() => {
                setFieldValue('prices', null);
                setFieldValue('offer', []);
              }}
            >
              <Icon className={s.iconEdit} img="edit" />
              <h4>Редактировать</h4>
            </button>
          </div>
        )}
      </div>

      {/* <div>
        <pre>{JSON.stringify(values, null, 2)}</pre>
      </div> */}
    </>
  );
};
