import { useFormikContext } from 'formik';
import { FC } from 'react';
import { ICountertopEdges, TMain } from '../../../../types';
import { Icon, TImg } from '../../../common/icon';
import s from './style.module.css';
import {
  countertopR,
  countertopQ,
  countertopL,
  countertopU,
  getCountertopDefault,
  islandDefault,
} from '../../../app/defaultValues';

type TType = 'frontEdge' | 'panels' | 'wallLedge' | 'lowering';
interface ITopButton {
  type: TType;
  active: boolean;
  label?: string;
  img?: string;
  isOpen?: boolean;
  onClick?: () => void;
}

const getLabel = (type: TType, label: string) => {
  if (label) return label;
  if (type === 'frontEdge') return 'Лицевая кромка';
  if (type === 'panels') return 'Стеновая панель';
  if (type === 'wallLedge') return 'Плинтус';
  return '';
};

const getIconStyle = (type: TType, active: boolean) => {
  if (type === 'wallLedge' || type === 'lowering')
    return active ? 'ok-green' : 'plus-green';
  if (type === 'frontEdge' || type === 'panels')
    return active ? 'ok-white' : 'plus-blue';
  return 'ok-white';
};

export const TopButton: FC<ITopButton> = ({
  type,
  active,
  label = '',
  img = '',
  isOpen = false,
  onClick,
}) => {
  const { values, setFieldValue } = useFormikContext<TMain>();

  const { type: topType, panels } = values.calculator.countertop;

  const isWallLedge = type === 'wallLedge';
  const isFrontEdge = type === 'frontEdge';
  const isLowering = type === 'lowering';
  const isPanels = type === 'panels';

  const hasMark = isWallLedge || isFrontEdge;
  const [mark, markStyle] = isWallLedge ? ['b', s.b] : ['k', s.k];

  const backgroundStyle = (() => {
    if (isWallLedge) return 'green';
    if (isLowering) return 'cyan';
    return 'blue';
  })();

  const getDefaultsByType = (
    type: TMain['calculator']['countertop']['type']
  ) => {
    if (type === 'Q') return countertopQ;
    if (type === 'R') return countertopR;
    if (type === 'L') return countertopL;
    if (type === 'U') return countertopU;
  };

  const onClickPanels = () =>
    setFieldValue(
      'calculator.countertop.panels',
      !panels?.length ? getDefaultsByType(topType)?.panels : []
    );

  const onClickWallLedge = () => {
    const topType = values.calculator.countertop.type;
    const edges = values.calculator.countertop.edges;

    if (active) {
      Object.keys(edges).forEach((ed) => {
        if (edges[ed as keyof ICountertopEdges] === 'plinth') {
          setFieldValue(`calculator.countertop.edges.${ed}`, null);
        }
      });
      setFieldValue(`calculator.countertop.wallLedge`, false);
    }

    if (!active) {
      let countertopDefault = getCountertopDefault(topType);
      let edgesDefault = Object.fromEntries(
        Object.entries(countertopDefault.edges).filter(
          ([key, value]) => value === 'plinth'
        )
      );

      setFieldValue('calculator.countertop', {
        ...values.calculator.countertop,
        edges: { ...values.calculator.countertop.edges, ...edgesDefault },
        wallLedge: true,
      });
    }
  };

  const onClickLowering = () => {
    if (active)
      setFieldValue('calculator.island.edges', {
        edge1: 'edge',
        edge2: 'edge',
        edge3: 'edge',
        edge4: 'edge',
      });
    if (!active)
      if (islandDefault) {
        setFieldValue('calculator.island.edges', islandDefault.edges);
      }
  };

  const onClickSelect = (() => {
    if (isWallLedge) return onClickWallLedge;
    if (isLowering) return onClickLowering;
    if (isPanels) return onClickPanels;
  })();

  return (
    <button
      onClick={onClick ? onClick : onClickSelect}
      className={`pm14 ${s.topButton} ${active ? s[backgroundStyle] : ''}`}
    >
      <div className={s.label}>
        {getLabel(type, label)}
        {hasMark && active && (
          <span className={markStyle}>
            <Icon img={mark as TImg} />
            {isFrontEdge && (
              <Icon
                className={s.arrow}
                img={isOpen ? 'arrow-up-white' : 'arrow-down-white'}
              />
            )}
          </span>
        )}
      </div>
      <div className={s.iconContainer}>
        {hasMark && active && img && <img className={s.img} src={img} alt="" />}
        <Icon img={getIconStyle(type, active)} width={36} />
      </div>
    </button>
  );
};
