import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import { TMain } from '../../../../../types';
import { api } from '../../../../../api/api';
import { InputData } from './input-data';
import { useDebounce } from '../../../../../hooks/useDebounce';
import {
  // isEmail,
  isEmpty,
  isMargin,
  isPhoneNumber,
} from '../../../../../validation';
import s from './style.module.css';

export const OrderForm: FC<{ workerName: string; worker_id: number }> = ({
  workerName,
  worker_id,
}) => {
  const {
    values: {
      offer,
      calc_uid,
      settings: { isLoggedIn },
    },
    setFieldValue,
  } = useFormikContext<TMain>();

  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [cities, setCities] = useState<string[]>([]);
  const [complete, setComplete] = useState(false);

  type TOrderForm = {
    fname: string;
    email: string;
    phone: string;
    city: string;
    contact_type: string;
    worker_name: string;
    worker_id: number;
    calc_uid: string;
    margin: string;
    credentials: null | { login: string; password: string };
  };

  const [orderForm, setOrderForm] = useState<TOrderForm>({
    fname: '',
    email: '',
    phone: '+7',
    city: '',
    contact_type: 'Частное лицо',
    worker_name: workerName,
    worker_id,
    calc_uid,
    margin: '',
    credentials: null,
  });

  useEffect(() => {
    if (isLoggedIn) {
      setOrderForm({
        ...orderForm,
        credentials: {
          login: localStorage.getItem('login') || '',
          password: localStorage.getItem('password') || '',
        },
      });
    }
  }, [isLoggedIn]);

  const [clicked, setClicked] = useState(false);
  const [validation, setValidation] = useState({
    fname: true,
    email: true,
    phone: true,
    city: true,
    margin: true,
  });

  const getCities = async () => {
    const cities = await api
      .cities(orderForm.city.toLowerCase())
      .then(({ data }) =>
        data.map(
          ({ attributes: { name } }: { attributes: { name: string } }) => name
        )
      );

    return cities;
  };

  const debouncedCities: string = useDebounce<string>(orderForm.city, 500);

  useEffect(() => {
    if (debouncedCities.length > 0) {
      getCities().then((data) => setCities(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCities]);

  useEffect(() => {
    if (cities.length === 1) {
      setOrderForm({ ...orderForm, city: cities[0] });
      setCities([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities]);

  const handlePostOrder = () => {
    setIsSent(true);
    setLoading(true);
    api
      .placeOrder(orderForm)
      .then(({ data }) => {
        if ('errorMessage' in data) {
          setIsSent(false);
          alert('Произошла ошибка: ' + data['errorMessage']);
        } else {
          setComplete(true);

          if (isLoggedIn) {
            setFieldValue('offer', [...offer, data]);
          }

          if (!isLoggedIn) {
            window.location.href = '/';
          }
        }
        //
      })
      .catch((err) => alert(JSON.stringify(err)))
      .finally(() => setLoading(false));
  };

  const validate = () => {
    if (!isEmpty(orderForm.fname)) {
      setValidation({ ...validation, fname: false });
      return false;
    }
    // if (!isEmail(orderForm.email)) {
    //   setValidation({ ...validation, email: false });
    //   return false;
    // }
    if (!isEmpty(orderForm.city)) {
      if (!isLoggedIn) {
        setValidation({ ...validation, city: false });
        return false;
      }
    }
    if (!isPhoneNumber(orderForm.phone)) {
      setValidation({ ...validation, phone: false });
      return false;
    }
    if (!isMargin(orderForm.margin)) {
      if (isLoggedIn) {
        setValidation({ ...validation, margin: false });
        return false;
      }
    }
    return true;
  };

  return (
    <div className={s.orderForm}>
      {complete && !isLoggedIn && (
        <h1>
          Спасибо за заказ!
          <br />С вами свяжется обработчик в ближайшее время.
        </h1>
      )}

      {complete && isLoggedIn && (
        <h1>"КП сформировано! Нажмите кнопку "КП", чтобы к нему перейти"</h1>
      )}

      {!complete && (
        <>
          {isLoggedIn ? <h1>Создать КП</h1> : <h1>Сохранить расчет</h1>}

          {isLoggedIn ? (
            <div>
              <h2 style={{ marginBottom: '5px' }}>Обработчик: {workerName}</h2>
              <div>Ссылка на КП появится на странице</div>
            </div>
          ) : (
            <div>
              Ссылку на расчет выполучите на почту при заполнении всех
              контактных данных
            </div>
          )}
          <br />
          <InputData
            value={orderForm.fname}
            onChange={(e) =>
              setOrderForm({ ...orderForm, fname: e.target.value })
            }
            onBlur={() => {
              if (isEmpty(orderForm.fname)) {
                setValidation({ ...validation, fname: true });
              } else {
                setValidation({ ...validation, fname: false });
              }
            }}
            error={!validation.fname}
            label="Имя"
          />
          <InputData
            value={orderForm.email}
            onChange={(e) =>
              setOrderForm({ ...orderForm, email: e.target.value })
            }
            onBlur={() => {
              // if (isEmail(orderForm.email)) {
              //   setValidation({ ...validation, email: true });
              // } else {
              //   setValidation({ ...validation, email: false });
              // }
            }}
            error={!validation.email}
            label="Почта"
          />

          {!isLoggedIn && (
            <InputData
              value={orderForm.city}
              onChange={(e) => {
                setOrderForm({ ...orderForm, city: e.target.value });
              }}
              onBlur={() => {
                if (isEmpty(orderForm.city)) {
                  setValidation({ ...validation, city: true });
                } else {
                  setValidation({ ...validation, city: false });
                }
              }}
              error={!validation.city}
              label="Город"
            />
          )}

          <div>Род деятельности</div>
          <div className={s['select-wrapper']}>
            <select
              onChange={(e) => {
                setOrderForm({ ...orderForm, contact_type: e.target.value });
              }}
              className={s.select}
            >
              <option>Частное лицо</option>
              <option>Дизайнер</option>
              <option>Прораб</option>
              <option>Кухонный салон</option>
              <option>Другой</option>
            </select>
          </div>

          <InputData
            maxLength={20}
            value={orderForm.phone}
            onChange={(e) =>
              setOrderForm({ ...orderForm, phone: e.target.value })
            }
            onBlur={() => {
              if (isPhoneNumber(orderForm.phone)) {
                setValidation({ ...validation, phone: true });
              } else {
                setValidation({ ...validation, phone: false });
              }
            }}
            error={!validation.phone}
            label="Телефон"
          />

          {isLoggedIn && (
            <InputData
              maxLength={5}
              value={orderForm.margin}
              onChange={(e) =>
                setOrderForm({ ...orderForm, margin: e.target.value })
              }
              onBlur={() => {
                if (isMargin(orderForm.margin)) {
                  setValidation({ ...validation, margin: true });
                } else {
                  setValidation({ ...validation, margin: false });
                }
              }}
              number
              error={!validation.margin}
              label="Скидка-наценка (%)"
              placeholder="0"
            />
          )}

          {!loading && <div>{clicked && 'Необходимо заполнить все поля'}</div>}

          <button
            onClick={() => {
              setClicked(true);
              if (validate()) {
                handlePostOrder();
              }
            }}
            className={s.buttonPost}
            disabled={isSent}
          >
            {loading && (
              <div className={s.ldsRing}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
            {!loading && 'Создать КП'}
          </button>

          {cities.length > 1 && (
            <div className={s.citiesList}>
              {cities.map((city, i) => {
                if (i < 7) {
                  return (
                    <div
                      key={city}
                      onClick={() => setOrderForm({ ...orderForm, city })}
                    >
                      {city}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};
