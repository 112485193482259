import { FC } from 'react';
import { useFormikContext } from 'formik';
import { TMain } from '../../../../types';
import { Layer } from '../../../common/layer';
import { Line } from './line';

export const Edges: FC = () => {
  const { values } = useFormikContext<TMain>();

  const { type, edges, isActive } = values.calculator.countertop;

  const { edge1, edge2, edge3, edge4, edge5, edge6, edge7, edge8 } = edges;

  if (!isActive) return null;

  if (type === 'U')
    return (
      <Layer>
        <Line type={edge1} position="outerMiddle" />
        <Line type={edge2} position="outerLeft" />
        <Line type={edge3} position="outerLeftBottom" />
        <Line type={edge4} position="innerLeft" />
        <Line type={edge5} position="innerMiddle" />
        <Line type={edge6} position="innerRight" />
        <Line type={edge7} position="outerRightBottom" />
        <Line type={edge8} position="outerRight" />
      </Layer>
    );

  if (type === 'L')
    return (
      <Layer>
        <Line type={edge1} position="outerMiddle" />
        <Line type={edge2} position="outerRight" />
        <Line type={edge3} position="outerLeftBottom" />
        <Line type={edge4} position="outerLeft" />
        <Line type={edge5} position="innerMiddle" />
        <Line type={edge6} position="innerLeft" />
      </Layer>
    );

  if (type === 'R')
    return (
      <Layer>
        <Line type={edge1} position="outerMiddle" />
        <Line type={edge2} position="outerLeft" />
        <Line type={edge3} position="outerRightBottom" />
        <Line type={edge4} position="outerRight" />
        <Line type={edge5} position="innerMiddle" />
        <Line type={edge6} position="innerRight" />
      </Layer>
    );

  if (type === 'Q') {
    return (
      <Layer>
        <Line type={edge1} position="outerMiddle" />
        <Line type={edge2} position="outerLeft" />
        <Line type={edge3} position="outerBottom" />
        <Line type={edge4} position="outerRight" />
      </Layer>
    );
  }

  return null;
};
