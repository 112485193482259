import { FC } from 'react';
import { useFormikContext } from 'formik';
import { TMain } from '../../../../types';
import { Layer } from '../../../common/layer';
import { SizeControl } from '../../controls/size-control';
import { SelectSize } from '../../controls/select-size';
import { PanelSizes } from '../../controls/panel-size';
import { ShowMain } from '../../controls/show-main';
import s from './style.module.css';

export const Sizes: FC = () => {
  const { values } = useFormikContext<TMain>();
  const { isMobile } = useFormikContext<TMain>().values.settings;
  const { type, isActive } = values.calculator.countertop;

  const fieldName = 'calculator.countertop.';

  const PANEL_RANGE = {
    height: { min: 2, max: 300 },
    width: { min: 2, max: 1000 },
  };
  const LENGTH1_RANGE = { min: 60, max: 1000 };
  const LENGTH2_RANGE = { min: 10, max: 700 };
  const LENGTH3_RANGE = { min: 10, max: 700 };
  const WIDTH1_RANGE = { min: 10, max: 700 };
  const WIDTH2_RANGE = { min: 10, max: 700 };
  const WIDTH2_WIDE_RANGE = { min: 70, max: 1000 };
  const WIDTH3_RANGE = { min: 70, max: 1000 };

  if (type === 'L') {
    return (
      <Layer>
        {isActive && (
          <>
            <SizeControl
              fieldName={`${fieldName}sizes.length1`}
              position={{ top: 9, left: 50 }}
              maxLength={4}
              range={LENGTH1_RANGE}
            />
            <SizeControl
              fieldName={`${fieldName}sizes.width1`}
              position={{ top: 26.5, left: 50 }}
              range={WIDTH1_RANGE}
              s={s}
            />
            <SizeControl
              fieldName={`${fieldName}sizes.length2`}
              position={{ top: 72.6, left: 20 }}
              range={LENGTH2_RANGE}
              s={s}
            />
            <SizeControl
              fieldName={`${fieldName}sizes.width2`}
              position={{ top: 50, left: 14 }}
              range={WIDTH2_RANGE}
            />
            {!isMobile && (
              <SelectSize
                label="Толщина"
                fieldName="calculator.countertop.sizes.thickness"
                position="thickness"
              />
            )}
          </>
        )}
        {!isMobile && <PanelSizes range={PANEL_RANGE} />}
        {!isActive && <ShowMain />}
      </Layer>
    );
  }

  if (type === 'R') {
    return (
      <Layer>
        {isActive && (
          <>
            <SizeControl
              fieldName={`${fieldName}sizes.length1`}
              position={{ top: 9, left: 50 }}
              maxLength={4}
              range={LENGTH1_RANGE}
            />
            <SizeControl
              fieldName={`${fieldName}sizes.width1`}
              position={{ top: 26.5, left: 50 }}
              range={WIDTH1_RANGE}
              s={s}
            />
            <SizeControl
              fieldName={`${fieldName}sizes.length2`}
              position={{ top: 72.6, left: 80 }}
              range={LENGTH2_RANGE}
              s={s}
            />
            <SizeControl
              fieldName={`${fieldName}sizes.width2`}
              position={{ top: 50, left: 86 }}
              range={WIDTH2_RANGE}
            />
            {!isMobile && (
              <SelectSize
                label="Толщина"
                fieldName="calculator.countertop.sizes.thickness"
                position="thickness"
              />
            )}
          </>
        )}

        {!isMobile && <PanelSizes range={PANEL_RANGE} />}
        {!isActive && <ShowMain />}
      </Layer>
    );
  }

  if (type === 'Q') {
    return (
      <Layer>
        {isActive && (
          <>
            <SizeControl
              fieldName={`${fieldName}sizes.length1`}
              position={{ top: 9, left: 50 }}
              maxLength={4}
              range={LENGTH1_RANGE}
            />
            <SizeControl
              fieldName={`${fieldName}sizes.width1`}
              position={{ top: 26.5, left: 50 }}
              range={WIDTH1_RANGE}
              s={s}
            />
            {!isMobile && (
              <SelectSize
                label="Толщина"
                fieldName="calculator.countertop.sizes.thickness"
                position="thickness"
              />
            )}
          </>
        )}
        {!isMobile && <PanelSizes range={PANEL_RANGE} />}
        {!isActive && <ShowMain />}
      </Layer>
    );
  }

  // П-образная столешница
  return (
    <Layer>
      {isActive && (
        <>
          <SizeControl
            fieldName={`${fieldName}sizes.length1`}
            position={{ top: 9, left: 50 }}
            maxLength={4}
            range={LENGTH1_RANGE}
          />
          <SizeControl
            fieldName={`${fieldName}sizes.width1`}
            position={{ top: 26.5, left: 50 }}
            range={WIDTH1_RANGE}
            s={s}
          />
          <SizeControl
            fieldName={`${fieldName}sizes.width2`}
            position={{ top: 50, left: 14 }}
            maxLength={4}
            range={WIDTH2_WIDE_RANGE}
          />
          <SizeControl
            fieldName={`${fieldName}sizes.width3`}
            position={{ top: 50, left: 86 }}
            maxLength={4}
            range={WIDTH3_RANGE}
          />
          <SizeControl
            fieldName={`${fieldName}sizes.length2`}
            position={{ top: 72.6, left: 20 }}
            range={LENGTH2_RANGE}
            s={s}
          />
          <SizeControl
            fieldName={`${fieldName}sizes.length3`}
            position={{ top: 72.6, left: 80 }}
            range={LENGTH3_RANGE}
            s={s}
          />
          {!isMobile && (
            <SelectSize
              label="Толщина"
              fieldName="calculator.countertop.sizes.thickness"
              position="thickness"
            />
          )}
        </>
      )}

      {!isMobile && <PanelSizes range={PANEL_RANGE} />}
      {!isActive && <ShowMain />}
    </Layer>
  );
};
